@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


*{
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth; 
}
svg {
  shape-rendering: geometricprecision !important;
}
.MuiTab-root{
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize !important;
}
a{
  border-bottom: unset ;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
h1 {
  margin-top: 0;
  font-size: 4.209rem;
}

h2 {font-size: 3.157rem;}

h3 {font-size: 2.369rem;}

h4 {font-size: 1.777rem;}

h5 {font-size: 1.333rem;}

small, .text_small {font-size: 0.75rem;}
@media (max-width: 1024px) {
  h1 {
    margin-top: 0;
    font-size: 1.802rem;
  }
  
  h2 {font-size: 1.602rem;}
  
  h3 {font-size: 1.424rem;}
  
  h4 {font-size: 1.266rem;}
  
  h5 {font-size: 1.125rem;}
  
  small, .text_small {font-size: 0.889rem;}
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-slideshow-wrapper,.slide{
  width: 90% !important;
  margin: 0 auto ;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}
.each-slide-effect > div > img{
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
}
@media (min-width: 427px) and (max-width: 1024px) {
  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
  .each-slide-effect > div > img{
    object-fit: contain;
    height: 100%;
  }

}
@media (max-width: 431px) {
  .react-slideshow-wrapper,.slide{
    width: 80% !important;
    margin: 0 auto ;
  }
  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  .each-slide-effect > div > img{
    object-fit: contain;
    width:100%;
    height: 100%;
  }

}
